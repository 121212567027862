// @import "~react-image-gallery/styles/scss/image-gallery.scss";
.App {
  text-align: center;
  height: 100vh;
  // width: 100vw;
  background-color: #ffffff;
  font-family: Roboto;
}
.selection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media only screen 
  and (max-device-width: 960px) {
    height: unset;
  }
}
.selection a {
  text-decoration: none;
  z-index: 1;
}
.selection small {
  z-index: 1;
  color: white;
}
.info {
  justify-content: center;
}
.card {
  width: 275px;
  min-width: 275px;
  height: 175px;
  min-height: 175px;
  margin: 10px;
  padding: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 1;
}

.card:hover {
  cursor: pointer;
  box-shadow: -1px 10px 29px 0px rgba(0,0,0,0.8);
}
.dinner-hero {
  min-height: 100%;
  min-width: 1024px;
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  z-index: 0;
}

.loader {
  display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.hero {
  height: 500px;
  width: 500px;
  transition: .5s;
  &:hover {
    cursor: pointer;
    -moz-box-shadow:    3px 3px 5px 6px #ccc;
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
    box-shadow:         3px 3px 5px 6px #ccc;
  }
  @media only screen 
  and (max-device-width: 960px) {
    max-width: 100%;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  @media only screen 
  and (max-device-width: 960px) {
    flex-direction: column;
  }
}

.infoHome {
  margin-right: 6.25rem;
  font-weight: 300;
  @media only screen 
  and (max-device-width: 960px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.chooseBtn {
  padding: 10px 40px;
  background-color: #92e3a9;
  border: none;
  font-weight: bold;
  font-size: 14px;
  transition: .5s;
  &:hover {
    cursor: pointer;
    -moz-box-shadow:    3px 3px 5px 6px #ccc;
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
    box-shadow:         3px 3px 5px 6px #ccc;
  }
}

#animate {
  transition: 0.2s;
  position: absolute;
  background: transparent;
  display: none;
  #food {
    background: transparent;
  }
}
.foodNav {
  height: 3rem;
  width: 3rem;
}
.mainTitle {
  display: flex;
    text-decoration: none;
    color: #111;
    justify-content: center;
    align-items: center;
}
.selection_details {
  margin-bottom: 2rem;

  @media only screen 
  and (max-device-width: 960px) {
    margin-top: 2rem;
    padding: 0 15px;
  }
}
.reviewsContainer {
  width: 500px;
  height: 500px;
  overflow: scroll;
  overflow-x: hidden;
  transition: all 0.13s ease-in;
  border-radius: 0% 0% 0% 0% / 0% 0% 0% 0% ;
  box-shadow: 5px 5px rgba(0,0,0,.15);
  transition: all .4s ease;
  &:hover {
    border-radius: 0% 0% 50% 50% / 0% 0% 5% 5% ;
    box-shadow: 5px 5px rgba(0,0,0,.25);
  }
  &::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
  @media only screen 
  and (max-device-width: 960px) {
    width: 100%;
  }
}

.restarauntInfo {
  display: flex;
  @media only screen 
  and (max-device-width: 960px) {
    max-width: 100%;
    flex-direction: column;
  }
}
.opening_hours {
  display: flex;
  justify-content: center;
  align-items: center;
}
.name {
  margin: 0;
}

.review {
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: rgb(222, 246, 229);
  color: #111;
  &:last-child {
    border-bottom: none;
  }
  .reviewPrimaryInfo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .authorProfilePicture {
    height: 48px;
    width: 48px;
    margin-right: 1rem;
  }
  .quoteIcon {
    transform: scaleX(-1);
  }
  .authorName {
    margin-right: 1rem;
  }
  .reviewText {
    justify-content: center;
    display: flex;
    font-weight: 300;
  }
}

.emptyResponse {
  margin-right: 6.25rem;
}

.reviewsContainer::-webkit-scrollbar-thumb {
  background-color: red;
}

.imageContainer {
  .image {
    max-width: 500px;
    max-height: 500px;
    margin-right: 20px;
    @media only screen 
    and (max-device-width: 960px) {
      margin-bottom: 2rem;
    }
  }
}

.quota {
  @media only screen 
  and (min-device-width: 960px) {
    height: 500px;
  }
}

.vote {
  position: absolute !important;
  right: 0;
  text-decoration: none;
  padding: 1rem;
}

.voting {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}